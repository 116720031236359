.headerFullWidth {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.logoSection .logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1890ff;
  margin: 0;
}

.navSection {
  display: flex;
  align-items: center;
}

.burgerMenuButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.desktopNavFull {
  display: flex;
  align-items: center;
  gap: 20px;
}

.desktopNavLink {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s;
}

.desktopNavLink:hover {
  color: #1890ff;
}

.activeNavLink {
  color: #1890ff;
  font-weight: 600;
}

.profileDropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.username {
  font-weight: 500;
}

.mobileDrawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.drawerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.drawerContent {
  position: absolute;
  top: 0;
  right: 0;
  width: 280px;
  height: 100%;
  background: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.drawerLogo {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1890ff;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.drawerNav {
  padding: 20px;
}

.drawerNavLink {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  color: #333;
  padding: 12px 0;
  border-bottom: 1px solid #f0f0f0;
}

.drawerNavIcon {
  font-size: 1.2rem;
}

.drawerNavLabel {
  font-weight: 500;
}

.activeDrawerNavLink {
  color: #1890ff;
}

.drawerProfileSection {
  margin-top: 20px;
}

.mobileProfileDropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .burgerMenuButton {
    display: none;
  }
}

@media (max-width: 768px) {
  .desktopNavFull {
    display: none;
  }
}

.logoutButton {
  background-color: #1677ff;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logoutButton:hover {
  background-color: #1677ff;
  transform: scale(1.05);
}

.drawerLogoutButton {
  background-color: #3588fd;
  color: #ffffff;
  border: none;
  padding: 15px;
  text-align: center;
  width: 100%;
  border-radius: 6px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.drawerLogoutButton:hover {
  background-color: #1677ff;
  transform: scale(1.05);
}
