.headerFullWidth {
  background: #2e3130;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.logoSection {
  display: flex;
  align-items: center;
  width: 200px;
}

.logo {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.desktopNavFull {
  display: none;
}

.burgerMenuButton {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 1010;
  background-color: transparent;
  border: none;
  color: #fff;
}

.mobileDrawer {
  position: fixed;
  inset: 0;
  z-index: 1100;
}

.drawerOverlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.drawerContent {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  max-width: 85%;
  height: 100%;
  background-color: #1f2937;
  color: #ffffff;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.2);
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #374151;
}

.drawerLogo {
  max-width: 100%;
  height: auto;
}

.closeButton {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.drawerNav {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.drawerNavLink {
  display: flex;
  align-items: center;
  padding: 15px;
  color: #d1d5db;
  text-decoration: none;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.drawerNavLink:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #3b82f6;
}

.drawerNavIcon {
  margin-right: 15px;
  font-size: 1.5em;
  opacity: 0.7;
}

.drawerNavLabel {
  font-weight: 500;
}

.activeDrawerNavLink {
  background-color: rgba(59, 130, 246, 0.2);
  color: #3b82f6;
}

.contactButton {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.contactButton:hover {
  background-color: #2563eb;
}

.copyrightText {
  color: #9ca3af;
  font-size: 0.8em;
}

@media (min-width: 768px) {
  .burgerMenuButton {
    display: none;
  }

  .desktopNavFull {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-grow: 1;
  }

  .desktopNavLink {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    position: relative;
    transition: color 0.3s ease;
  }

  .desktopNavLink:hover {
    color: #007bff;
  }

  .activeNavLink {
    color: #007bff;
    font-weight: 600;
  }

  .activeNavLink::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff;
  }

  .headerContainer {
    max-width: 1400px;
    padding: 0 30px;
  }
}

.logoutButton {
  background-color: #1677ff;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logoutButton:hover {
  background-color: #3588fd;
  transform: scale(1.05);
}

.drawerLogoutButton {
  background-color: #1677ff;
  color: #ffffff;
  border: none;
  padding: 15px;
  text-align: center;
  width: 100%;
  border-radius: 6px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.drawerLogoutButton:hover {
  background-color: #3588fd;
  transform: scale(1.05);
}
