.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 16px;
}

.formContainer {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  position: relative;
}

.formHeader {
  text-align: center;
  margin-bottom: 25px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 15px;
}

.formHeader h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.formHeader p {
  color: #666;
  font-size: 14px;
}

.formGroup {
  margin-bottom: 20px;
  position: relative;
}

.formGroup label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.inputIcon {
  margin-right: 10px;
  color: #007bff;
  width: 20px;
  height: 20px;
}

.formGroup input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.formGroup input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

.error {
  color: #dc3545;
  font-size: 13px;
  margin-top: 5px;
}

.formGroup button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formGroup button:hover {
  background-color: #0056b3;
}

.formGroup button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .formContainer {
    padding: 20px;
    margin: 0 10px;
  }

  .formHeader h1 {
    font-size: 20px;
  }

  .formHeader p {
    font-size: 12px;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.successIcon {
  color: #28a745;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.modalHeader h2 {
  color: #333;
  margin-bottom: 10px;
}

.modalContent p {
  color: #666;
  margin-bottom: 15px;
}

.modalCloseBtn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modalCloseBtn:hover {
  background-color: #0056b3;
}
